import { IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonToolbar, useIonRouter, IonMenuButton } from "@ionic/react"
import { arrowBackOutline, closeOutline } from "ionicons/icons"
import './mainStarTraining.css'
import { useRecoilState, useRecoilValue } from "recoil"
import { listExerciseAtom, trainingModeAtom, trainingState } from "recoil/TrainingAtom"
import { VideoTraining } from "./components/video-training/VideoTraining"
import { orientationAtom } from "recoil/OrientationAtom"

export const MainStartTraining: React.FC = ()=>{ 

    const navigation = useIonRouter()

    const training = useRecoilValue(trainingState)
    const [listExercise, setListExercise] = useRecoilState(listExerciseAtom)
    const orientation = useRecoilValue(orientationAtom)
    const [trainingBegan, setTrainingBegan] = useRecoilState(trainingModeAtom);
    

    return(
        <IonPage>
            {orientation === 'portrait-primary' &&
            <IonHeader className={trainingBegan ? "hide" : "show"}>
                <IonToolbar>
                    <article style={{ display:'flex', width: 300 }}>
                        <IonButtons slot="start" onClick={()=> {
                            navigation.push('/quick-training', 'back' , 'replace')
                            }}>
                            <IonIcon style={{ width:24, height:24, marginLeft:10 }} icon={arrowBackOutline} />
                        </IonButtons>
                        <p style={{ fontSize: 16, marginLeft:10, fontWeight:700 }}>Entrenamiento3 de {Number(training.duracion)} min</p>
                    </article>
                </IonToolbar>
            </IonHeader>}
            <IonContent fullscreen>
                <div className="mst-container">
                    <VideoTraining training={training} listExercise={listExercise} />
                </div>
            </IonContent>
        </IonPage>
    )
}