import { useEffect, useState } from 'react';

//ionic
import { IonGrid, IonText, IonButton } from '@ionic/react';

//css
import './progress-component.css';

//hooks
import { useGetUserSessions } from 'hooks/sessions/useGetUserSessions';
import { useGetProgressHistory } from 'hooks/progress/useGetProgressHistory';

//recoil
import { useRecoilValue } from 'recoil';
import { userInfoState } from 'recoil/User';

interface CoachSession {
  idSession: number;
  title: string;
  category: string;
  label: string;
  contactInfo: string;
  description: string;
  eventURL: string;
  eventDate: number;
  duration: number;
  idCoach: number;
  fullName: string;
  role: string;
  keyCoach: string;
  videoURL: string | null;
  imageURL: string;
}

interface GroupedObject {
  [date: string]: CoachSession[];
}

const groupSessionsByDate = (sessions: CoachSession[]) => {
  const groupedSessions: { [date: string]: CoachSession[] } = {};

  sessions.forEach((session) => {
    const eventDate = new Date(session.eventDate);
    const formattedDate = eventDate.toISOString().split('T')[0];

    if (!groupedSessions[formattedDate]) {
      groupedSessions[formattedDate] = [];
    }

    groupedSessions[formattedDate].push(session);
  });

  const sortedDates = Object.keys(groupedSessions).sort((a, b) => {
    const timestampA = new Date(a).getTime();
    const timestampB = new Date(b).getTime();
    return timestampB - timestampA;
  });

  const sortedAndGroupedSessions: { [date: string]: CoachSession[] } = {};
  sortedDates.forEach((date) => {
    sortedAndGroupedSessions[date] = groupedSessions[date];
  });

  return sortedAndGroupedSessions;
};

export const HistorialSesiones: React.FC = () => {
  const [limit, setLimit] = useState(4);

  const user = useRecoilValue(userInfoState);
  const { idUser } = user;

  const { sessions: pastSessions, handleGetUserSessions: handleGetPastUserSessions } = useGetUserSessions();

  useEffect(() => {
    handleGetPastUserSessions(Number(idUser), { future: 1, limit });
  }, [handleGetPastUserSessions, idUser, limit]);

  const groupedObject: GroupedObject = groupSessionsByDate(pastSessions);

  return (
    <>
      <IonGrid className="p-historial-container">
        <IonText className="p-historial-title">Historial</IonText>
        {Object.entries(groupedObject).length !== 0 &&
          Object.entries(groupedObject).map(([date, sessions]) => {
            return (
              <>
                <div className="p-historial-div">
                  <IonText className="p-historial-day">{date}</IonText>
                </div>
                {sessions.map((element: any) => {
                  return (
                    <div className="flex" style={{ alignItems: 'center', marginTop: '16px' }}>
                      <div className="p-circle" style={{ background: '#FFE1D4' }}></div>
                      <div className="flex" style={{ flexDirection: 'column', width: '100%' }}>
                        <IonText className="p-historial-exercise">{element.title}</IonText>
                        <IonText className="p-historial-description">
                          {element.fullName} - {element.role}
                        </IonText>
                      </div>
                    </div>
                  );
                })}
              </>
            );
          })}

        <IonButton onClick={() => setLimit((prevState) => prevState + 2)} className="ls-button" fill="clear">
          Ver más Historial
        </IonButton>
      </IonGrid>
    </>
  );
};
