import { IonCheckbox, IonFab, IonFabButton, IonIcon, IonText, useIonRouter } from "@ionic/react";
import { Divider } from "components/Divider";
import { handleVideoTime } from "helper/handleVideoTime";
import { usePostUserExercisePoint } from "hooks/entrenamiento-rapido/usePostUserExercisePoint";
import { useOpenModal } from "hooks/modal/useOpenModal";
import { IExercisesQuick } from "interfaces/IEntraniento";
import { pause, play, playSkipBack, playSkipForward } from "ionicons/icons";
import { relative } from "path";
import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { emailAtom } from "recoil/EmailAtom";
import { exerciseMinutesDoneAtom, exercisePointsAtom, timerExerciseAtom } from "recoil/exercisesAtom";
import { trainingVideoModeAtom } from "recoil/TrainingAtom";
import { userInfoState } from "recoil/User";
import screenfull from 'screenfull';

interface ITimerProgressBar{ 
    totalTime: number,
    basisName: string,
    name: string,
    setIsPlaying: (isPlaying:boolean) => void,
    listExercise: IExercisesQuick[],
    index: number,
    setIndex: (index:number)=> void,
    setCurrentTime: (index:any)=> void,
    currentTime: number,
    setProgress: (progress:any) => void,
    isReady: boolean,
    showCountdown: boolean,
    setIsReady:(el:boolean)=>void,
    progress: number
}

export const TimerProgressBar: React.FC <ITimerProgressBar> = ({ progress, setIsReady, isReady, showCountdown, currentTime, setCurrentTime,setProgress, setIndex, index, listExercise, setIsPlaying, totalTime, basisName, name }) => {

  const [isRunning, setIsRunning] = useState(false);
  const [isOnFullScreen, setIsOnFullScreen] = useState(false);
  const indexRef = useRef<number>(0)
  const navigation = useIonRouter()
  const setExercisePoints = useSetRecoilState(exercisePointsAtom)
  const setTimerIsRunning = useSetRecoilState(timerExerciseAtom)
  const { handleOpenModal } = useOpenModal()
  const [trainingVideoMode, setTrainingVideoMode] = useRecoilState(trainingVideoModeAtom);
  const email = useRecoilValue(emailAtom);
  const user = useRecoilValue(userInfoState);
  
    useEffect(() => {
      let timer:any = null;
      if (isRunning && currentTime > 0) {
        timer = setInterval(() => {
          setCurrentTime((prevTime:any) => prevTime - 1);
          setProgress((prevTime:any) => prevTime + 1)
        }, 1000);
      }else if(currentTime === 0){ 
        handleOpenModal('finishedQuickTraining')
        navigation.push('/tabs/home','back', 'replace')
      }

      if(currentTime % 60 === 0 && progress !== 0 && isRunning){
        setExercisePoints( prevState => prevState + 1)
      }
  
      return () => {
        clearInterval(timer);
      };
    }, [currentTime, isRunning, totalTime, showCountdown]);

    useEffect(() => {
      if(showCountdown){
        setTimerIsRunning(false)
        setIsRunning(false)
        setIsPlaying(true)
      }else if(!showCountdown && isReady){
        setTimerIsRunning(true)
        setIsRunning(true)
        setIsPlaying(true)
      }else if(isReady){
        setTimerIsRunning(false)
        setIsRunning(false)
        setIsPlaying(true)
      }else if(!isReady){
        setTimerIsRunning(false)
        setIsRunning(false)
        setIsPlaying(true)
      }
    }, [showCountdown, isReady])
    
  
    const minutes = Math.floor(currentTime / 60);
    const seconds = currentTime % 60;
  
    const progressPercentage = ((totalTime - currentTime) / totalTime) * 100;
    var isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
    const progressBarStyle = {
      width: `${progressPercentage}%`,
      backgroundColor: '#D64200',
      height: '60px',
    };
  
    const handleStop = () => {
      setTimerIsRunning(false)
      setIsRunning(false);
      setIsPlaying(false)
    };
  
    const handleResume = () => {
      setTimerIsRunning(true)
      setIsPlaying(true)
      setIsRunning(true);
    };

    const handleBackVideo = ()=>{ 
      setIsPlaying(false)
      setIsRunning(false);
      setIsReady(false)
      setProgress(0)
      indexRef.current = index - 1
      setIndex(index - 1)
      setCurrentTime( handleVideoTime(indexRef.current, totalTime))
    }
    

    const handleNextVideo = ()=>{ 
      setIsPlaying(false)
      setIsRunning(false);
      setIsReady(false)
      setProgress(0)
      indexRef.current = index + 1
      setIndex(index + 1)
      setCurrentTime( handleVideoTime(indexRef.current, totalTime))
    }

    
    useEffect(() => {
      if (screenfull.isEnabled) {
        if(isOnFullScreen) {
          screenfull.request();
        } else {
          screenfull.exit();
        }
      }
    }, [isOnFullScreen]);
  
    return (
      <>
        <div style={{ display:'flex', width:'100%', padding:'24px', justifyContent:'center', height:'110px', position: trainingVideoMode ? "absolute":undefined, bottom: trainingVideoMode ? "0" :undefined, marginTop: trainingVideoMode ? "-375px" :"-175px", }}>
            <IonFab style={{ display:'flex', justifyContent:'center', alignItems:'end', width:'100%'}}>
              <IonFabButton disabled={index === 0} style={{ width:48, height:48 }} color={'orange'} onClick={handleBackVideo}>
                <IonIcon icon={playSkipBack}></IonIcon>
              </IonFabButton>
              { (user.email === "maurovillaseco@gmail.com" || user.email === "alejosanchez.8@gmail.com") && <IonCheckbox
                checked={trainingVideoMode}
                value={trainingVideoMode}
                  onIonChange={(e) => {
                    if(trainingVideoMode) {
                      setTrainingVideoMode(false);
                    } else {
                      setTrainingVideoMode(true);
                    }
                  }}
                  labelPlacement="end"
                  style={{ fontSize: 14, fontWeight: 400, position: 'absolute', left: 10, bottom: 10, zIndex: 999999999, color: trainingVideoMode ? "white" : undefined }}>
                  Modo Espejo
                </IonCheckbox>
              }
              {
                isRunning ? (
                    <IonFabButton style={{ width:58, height:58, marginRight:20, marginLeft:20 }} color={'orange'} onClick={handleStop}>
                      <IonIcon icon={pause}></IonIcon>
                    </IonFabButton>
              ) : (
                <IonFabButton disabled={showCountdown || !isReady} style={{ width:58, height:58, marginRight:20, marginLeft:20 }} color={'orange'} onClick={handleResume}>
                  <IonIcon icon={play}></IonIcon>
                </IonFabButton>
              )
              }
              <IonFabButton disabled={listExercise.length - 1 === index} style={{ width:48, height:48 }} color={'orange'} onClick={handleNextVideo}>
                <IonIcon icon={playSkipForward}></IonIcon>
              </IonFabButton>
            </IonFab>
        </div>

        <div className="links-dc" style={{ width: trainingVideoMode ? "12%":'100%', padding:'0.8rem', marginTop:"-40px", position:  trainingVideoMode ? "absolute": 'relative', bottom: trainingVideoMode ? "80px": undefined, display:'flex', justifyContent: trainingVideoMode ? '' : 'space-between', flexDirection: trainingVideoMode ? "column": undefined, zIndex: 999999 }}>
          <IonText 
                  id={String(listExercise[index].basisName)}
                  onClick={(e)=>{
                    setTimerIsRunning(false)
                    setIsRunning(false);
                    setIsPlaying(false)
                   }} 
                  color={'orange'} style={{ fontSize:16, fontWeight:700, marginTop:10, textDecoration:'underline', cursor: 'pointer' }}>Ver video completo</IonText>
          <IonText 
                  id={String(listExercise[index].basisName)}
                  onClick={(e)=>{
                    setIsOnFullScreen(!isOnFullScreen);
                   }} 
                  color={'orange'} style={{ fontSize:16, fontWeight:700, marginTop:10, textDecoration:'underline', cursor: 'pointer' }}>{isOnFullScreen ? 'Salir de pantalla completa' : 'Ver pantalla completa' } </IonText>
        </div>

        <div style={{ width: trainingVideoMode ? "18%" :'100%', right: trainingVideoMode ? "10px": undefined , border: '1px solid black', backgroundColor:'#393A3F', top: trainingVideoMode ? "20px" : undefined, position: trainingVideoMode ? "absolute": 'relative', zIndex:"9999" }}>
          <div style={progressBarStyle}>
            <article style={{ width:'100vw', display:'flex', justifyContent:'space-between', alignItems:'center', height:'100%'}}>
                <div style={{ height:'100%', display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'center', width:'100%', marginLeft:30}}>
                    <IonText color={'white'} style={{ fontSize: trainingVideoMode ? '10px': '14px', fontWeight:500}}>{basisName}</IonText>
                    <IonText color={'white'} style={{ fontSize: trainingVideoMode ? '14px': '20px', fontWeight:700, width:'90%', maxWidth: trainingVideoMode ? '17%': undefined, whiteSpace: trainingVideoMode ? 'nowrap': undefined, textOverflow: trainingVideoMode ? 'ellipsis': undefined, overflow: trainingVideoMode ? 'hidden  ': undefined }}>{name}</IonText>
                </div>
                <div style={{ marginRight:20 }}>
                    <IonText color={'white'} style={{ fontSize:'22px', fontWeight:700 }}>
                        {minutes.toString().padStart(2, '0')}:
                        {seconds.toString().padStart(2, '0')}
                    </IonText>
                </div>
            </article>
          </div>
        </div>
      </>
    );
  };
  