import React, { useState } from 'react';
import './stepperQuickTraining.css';

import { StepQuickTraining } from './StepQuickTraining';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { keyAccordionAtom, listExerciseAtom, trainingBeganAtom, trainingVideoModeAtom } from 'recoil/TrainingAtom';
import { useEffect } from 'react';
import { useGetListExerciseTraining } from 'hooks/entrenamiento-rapido/useGetListExerciseTraining';
import { IonAccordionGroup, IonButton, IonCheckbox, useIonRouter, useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react';

interface IStepper {
  data: any[];
}

export interface TrainingItem {
  idTraining: number;
  name: string;
  key: string;
  basisName: string;
  detailsTraining: string;
  detailsExercise: string;
  videoURL: string;
  idExercise: number;
  completed: number;
}

export const StepperQuickTraining: React.FC<IStepper> = ({ data }) => {
  const [listExercise, setListExercise] = useRecoilState(listExerciseAtom);
  const setkeyAccordion = useSetRecoilState(keyAccordionAtom);
  const { handleGetListExerciseTraining } = useGetListExerciseTraining();
  const navigation = useIonRouter();
  const [trainingBegan, setTrainingBegan] = useRecoilState(trainingBeganAtom);
  const [trainingVideoMode, setTrainingVideoMode] = useRecoilState(trainingVideoModeAtom);

  useEffect(() => {
    if (listExercise.length === 0) {
      handleGetListExerciseTraining(data);
    }
  }, [data]);

  const handleRedirect = () => {
    navigation.push('/quick-training/start', 'forward', 'replace');
  };

  useIonViewDidLeave(() => setkeyAccordion(''));
  useIonViewDidEnter(() => setkeyAccordion(''));

  return (
    <div className="stepper-container">
      <IonAccordionGroup onIonChange={(e) => setkeyAccordion(e.detail.value)}>
          {
              listExercise.length !== 0 ?
              listExercise.map( (element:any, index) => { 
                  
                  return( 
                  <div style={{ width:'100%', display:'flex' }} key={element.idExercise}>
                      <StepQuickTraining exercise={element} index={index} data={listExercise} state={element.completed} />
                  </div>)
              })
              :
              null
          }
      </IonAccordionGroup>
      <div style={{ width: '100%', position: 'fixed', bottom: 35, left: '7%', zIndex: 10000 }}>
        <IonButton
          onClick={() => {
            setTrainingBegan(true);
            handleRedirect();
          }}
          style={{ width: '85%' }}
          fill="solid"
          color={'orange'}
          expand="block"
        >
          {trainingBegan ? 'Reanudar entrenamiento' : 'Empezar entrenamiento'}{' '}
        </IonButton>
      </div>
    </div>
  );
};
